import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import H1Category from "../../components/h1Category"
import ContentPage from "../../layouts/contentPage"
import ProductBox from "../../components/productBox"
import ProductBoxCta from "../../components/productBox/productBoxCta"
import ProducstList from "../../components/productsList"
import Seo from "../../components/seo"

import ArrowRight from "../../images/arrow-right.svg"
import ProductBanner from "../../components/productBanner"
import Container from "../../components/Product/container"





const Category = () => (
    <Layout>
      <Seo title="Linia Relift" />
      <ContentPage>
            <H1Category color="magenta">Linia Relift</H1Category>
            <ProducstList>

                <ProductBox data-color="magenta">
                    <Link to="/produkty/liftingujace-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/liftingujace-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Liftingujące serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Liftingujące serum</h3>
                                <p>do szyi i dekoltu</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/produkty/odmladzajace-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/odmladzajace-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Odmładzające serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Odmładzające serum</h3>
                                <p>do twarzy na dzień SPF 15</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/produkty/ultranaprawcze-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/ultranaprawcze-serum.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Ultranaprawcze serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Ultranaprawcze serum</h3>
                                <p>do twarzy na noc</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="magenta">
                    <Link to="/produkty/wygladzajace-serum">
                        <StaticImage
                        src="../../images/media/products/relift/category/wygladzajace-serum.png"
                        loading="lazy"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Wygładzające serum"
                        />
                        <ProductBoxCta className="cta" data-color="magenta">
                            <div>
                                <h3>Wygładzające serum</h3>
                                <p>pod oczy</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBanner wide productLine="relift" />


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
